import service from './service'
import { CONTENT_TYPE } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'

import { AxiosResponse } from 'axios'
// interface SearchFunc {
//   (url: string, data: any): Promise<IResponse<T>>;
// }
export type SwaggerResponse<R> = AxiosResponse<R>

interface CmsResponse {
  page?: {
    count?: number
    page?: number
    pageSize?: number
  }
  data: any
  code: number
  msg?: string
}

export const request = (url: string, data): any => {
  const userStore = useUserStoreWithOut()
  return service.request({
    url,
    ...data,
    headers: {
      'Content-Type': CONTENT_TYPE,
      [userStore.getTokenKey ?? 'Authorization']: userStore.getToken ?? '',
      ...data.headers
    }
  }) as Promise<CmsResponse>
}

// export default {
//   get: <T = any>(option: AxiosConfig) => {
//     return request({ method: 'get', ...option }) as Promise<IResponse<T>>
//   },
//   post: <T = any>(option: AxiosConfig) => {
//     return request({ method: 'post', ...option }) as Promise<IResponse<T>>
//   },
//   delete: <T = any>(option: AxiosConfig) => {
//     return request({ method: 'delete', ...option }) as Promise<IResponse<T>>
//   },
//   put: <T = any>(option: AxiosConfig) => {
//     return request({ method: 'put', ...option }) as Promise<IResponse<T>>
//   },
//   cancelRequest: (url: string | string[]) => {
//     return service.cancelRequest(url)
//   },
//   cancelAllRequest: () => {
//     return service.cancelAllRequest()
//   }
// }
