// @ts-ignore
/* eslint-disable */
import { request } from '@/axios'

/** 新增菜单 POST /open-user/back/menu/menu */
export async function saveUsingPost4(body: API.SaveMenuDTO, options?: { [key: string]: any }) {
  return request('/open-user/back/menu/menu', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** 更新菜单 PUT /open-user/back/menu/menu/${param0} */
export async function updateUsingPut(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.updateUsingPUTParams,
  body: API.UpdateMenuDTO,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params
  return request(`/open-user/back/menu/menu/${param0}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    params: { ...queryParams },
    data: body,
    ...(options || {})
  })
}

/** 详情 GET /open-user/back/menu/menu/${param0}/detail */
export async function detailUsingGet1(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.detailUsingGET1Params,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params
  return request(`/open-user/back/menu/menu/${param0}/detail`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {})
  })
}

/** 当前登录用户树状菜单 GET /open-user/back/menu/menu/current-tree */
export async function currentTreeUsingGet(options?: { [key: string]: any }) {
  return request('/open-user/back/menu/menu/current-tree', {
    method: 'GET',
    ...(options || {})
  })
}

/** 禁用启用菜单 PUT /open-user/back/menu/menu/enable */
export async function enableUsingPut2(body: API.EnableMenuDTO, options?: { [key: string]: any }) {
  return request('/open-user/back/menu/menu/enable', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** 查询树状菜单 GET /open-user/back/menu/menu/tree */
export async function queryForTreeUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.queryForTreeUsingGETParams,
  options?: { [key: string]: any }
) {
  return request('/open-user/back/menu/menu/tree', {
    method: 'GET',
    params: {
      ...params
    },
    ...(options || {})
  })
}
