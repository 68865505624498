import { defineStore } from 'pinia'
import { store } from '../index'
import { listAllUsingGet1 } from '@/servers/api/xitongguanlizidianxiangguan'
type DictItemVO = {
  enabled?: number
  /** 类型编码 */
  key: string
  /** 类型名称 */
  value?: string
}

type DictVO = {
  /** 字典编码 */
  [key: string]: DictItemVO[]
}
interface dictState {
  dictData?: DictVO
}
export const useDictStore = defineStore('dict', {
  state: (): dictState => {
    return {
      dictData: {}
    }
  },
  getters: {},
  actions: {
    setDict(value) {
      this.dictData = value
    },
    async getDictAll() {
      const dict: DictVO = {}
      const res = await listAllUsingGet1({})
      if (res.code == 200) {
        if (res.data) {
          res.data.forEach((item) => {
            dict[item.code] = item.items
          })
        }
      }
      this.setDict(dict)
    },
    getDictItem(code: string) {
      if (this.dictData) {
        return this.dictData[code] || ([] as DictItemVO[])
      } else {
        return [] as DictItemVO[]
      }
    }
  },
  persist: true
})

export const useDictStoreWithOut = () => {
  return useDictStore(store)
}
