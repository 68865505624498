// @ts-ignore
/* eslint-disable */
import { request } from '@/axios'

/** 用户基本信息 GET /open-user/back/auth/info */
export async function infoUsingGet(options?: { [key: string]: any }) {
  return request('/open-user/back/auth/info', {
    method: 'GET',
    ...(options || {})
  })
}

/** 后台登出 POST /open-user/back/auth/login-out */
export async function logOutUsingPost(options?: { [key: string]: any }) {
  return request('/open-user/back/auth/login-out', {
    method: 'POST',
    ...(options || {})
  })
}

/** 发送登录短信 GET /open-user/back/auth/send-login-sms */
export async function sendLoginSmsUsingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.sendLoginSmsUsingGETParams,
  options?: { [key: string]: any }
) {
  return request('/open-user/back/auth/send-login-sms', {
    method: 'GET',
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 后台短信验证码登录 POST /open-user/back/auth/sms-login */
export async function smsLoginUsingPost(body: API.SmsLoginDTO, options?: { [key: string]: any }) {
  return request('/open-user/back/auth/sms-login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}
