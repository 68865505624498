// @ts-ignore
/* eslint-disable */
import { request } from '@/axios'

/** 全量数据字典 GET /open-base/back/dict/all */
export async function listAllUsingGet1(options?: { [key: string]: any }) {
  return request('/open-base/back/dict/all', {
    method: 'GET',
    ...(options || {})
  })
}

/** 启用禁用字典 PUT /open-base/back/dict/enable */
export async function enableUsingPut(body: API.EnableSysDictDTO, options?: { [key: string]: any }) {
  return request('/open-base/back/dict/enable', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** 分页查询字典 GET /open-base/back/dict/page */
export async function pageUsingGet1(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.pageUsingGET1Params,
  options?: { [key: string]: any }
) {
  return request('/open-base/back/dict/page', {
    method: 'GET',
    params: {
      ...params
    },
    ...(options || {})
  })
}

/** 添加字典 POST /open-base/back/dict/save */
export async function saveUsingPost2(body: API.SaveSysDictDTO, options?: { [key: string]: any }) {
  return request('/open-base/back/dict/save', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}

/** 更新字典 POST /open-base/back/dict/update */
export async function updateUsingPost1(body: API.SaveSysDictDTO, options?: { [key: string]: any }) {
  return request('/open-base/back/dict/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body,
    ...(options || {})
  })
}
