import { defineStore } from 'pinia'
import { asyncRouterMap, constantRouterMap } from '@/router'
import {
  generateRoutesByFrontEnd,
  generateRoutesByServer,
  flatMultiLevelRoutes
} from '@/utils/routerHelper'
import { store } from '../index'
import { cloneDeep } from 'lodash-es'
import {
  // queryForTreeUsingGet,
  currentTreeUsingGet
} from '@/servers/api/xitongguanlicaidanxiangguan'
const modules = import.meta.glob('/src/**/*.vue')

export interface PermissionState {
  routers: AppRouteRecordRaw[]
  addRouters: AppRouteRecordRaw[]
  isAddRouters: boolean
  menuTabRouters: AppRouteRecordRaw[]
}

export const usePermissionStore = defineStore('permission', {
  state: (): PermissionState => ({
    routers: [],
    addRouters: [],
    isAddRouters: false,
    menuTabRouters: []
  }),
  getters: {
    getRouters(): AppRouteRecordRaw[] {
      return this.routers
    },
    getAddRouters(): AppRouteRecordRaw[] {
      return flatMultiLevelRoutes(cloneDeep(this.addRouters))
    },
    getIsAddRouters(): boolean {
      return this.isAddRouters
    },
    getMenuTabRouters(): AppRouteRecordRaw[] {
      return this.menuTabRouters
    }
  },
  actions: {
    setRoutes(list) {
      const hiddenlist = ['ManagementCenter', 'Add', 'ArrangeDetails', 'Edit']
      list?.forEach((e) => {
        // if (e.attachJson === '/views/Review/Review') {
        //   e.component = e.component ? e.component : modules[`/src${e.attachJson}.vue`]
        // } else {
        //   e.component = e.component ? e.component : () => import(`${e.attachJson}`)
        // }
        e.component = e.component ? e.component : modules[`/src${e.attachJson}.vue`]
        e.path = e.menuPath || ''
        e.meta = {
          title: e.title || e.name || e.parentName || '',
          icon: e.icon || '',
          hidden: !e.enabled || !e.selected
        }
        if (hiddenlist.includes(e.code)) {
          e.meta.hidden = true
        }
        e.children = e.childMenus || []
        if (e?.childMenus) this.setRoutes(e.childMenus)
      })
    },
    generateRoutes(
      type: 'server' | 'frontEnd' | 'static',
      routers?: AppCustomRouteRecordRaw[] | string[]
    ): Promise<unknown> {
      return new Promise<void>(async (resolve) => {
        let routerMap: AppRouteRecordRaw[] = []
        if (type === 'server') {
          // 模拟后端过滤菜单
          routerMap = generateRoutesByServer(routers as AppCustomRouteRecordRaw[])
        } else if (type === 'frontEnd') {
          // 模拟前端过滤菜单
          routerMap = generateRoutesByFrontEnd(cloneDeep(asyncRouterMap), routers as string[])
        } else if (type === 'static') {
          // 动态获取接口路由
          // const res = await queryForTreeUsingGet({})
          const res = await currentTreeUsingGet({})
          console.log(res)
          routerMap = res.data || []
          this.setRoutes(routerMap)
          // routerMap = cloneDeep(asyncRouterMap)
        } else {
          // 直接读取静态路由表
          routerMap = cloneDeep(asyncRouterMap)
        }
        // 动态路由，404一定要放到最后面
        this.addRouters = routerMap.concat([
          {
            path: '/:path(.*)*',
            redirect: '/404',
            name: '404Page',
            meta: {
              hidden: true,
              breadcrumb: false
            }
          }
        ])

        // 渲染菜单的所有路由
        this.routers = cloneDeep(constantRouterMap).concat(routerMap)
        resolve()
      })
    },
    setIsAddRouters(state: boolean): void {
      this.isAddRouters = state
    },
    setMenuTabRouters(routers: AppRouteRecordRaw[]): void {
      this.menuTabRouters = routers
    }
  },
  persist: {
    paths: ['routers', 'addRouters', 'menuTabRouters']
  }
})

export const usePermissionStoreWithOut = () => {
  return usePermissionStore(store)
}
