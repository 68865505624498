import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/welcome',
    name: 'Root',
    meta: {
      hidden: true
    },
    children: [
      {
        path: '/welcome',
        component: () => import('@/views/Welcome/Welcome.vue'),
        name: 'Welcome',
        meta: {
          hidden: true,
          title: '主页'
        }
      }
    ]
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/user',
    component: Layout,
    redirect: '/user/review',
    name: 'User',
    meta: {
      title: '用户管理',
      icon: 'gravity-ui:person-gear',
      alwaysShow: true
    },
    children: [
      {
        path: 'review',
        name: 'Review',
        component: () => import('@/views/Review/Review.vue'),
        meta: {
          title: '资格审查'
        }
      }
    ]
  },
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting/menu',
    name: 'Setting',
    meta: {
      title: '系统设置',
      icon: 'gravity-ui:gear',
      alwaysShow: true
    },
    children: [
      {
        path: 'menu',
        name: 'Menu',
        component: () => import('@/views/Menu/Menu.vue'),
        meta: {
          title: '菜单管理'
        }
      },
      {
        path: 'role',
        name: 'Role',
        component: () => import('@/views/Role/Role.vue'),
        meta: {
          title: '角色管理'
        }
      },
      {
        path: 'adminUser',
        name: 'AdminUser',
        component: () => import('@/views/User/User.vue'),
        meta: {
          title: '后管用户'
        }
      },
      {
        path: 'dict',
        name: 'Dict',
        component: () => import('@/views/Dict/Dict.vue'),
        meta: {
          title: '字典管理'
        }
      }
    ]
  },
  {
    path: '/management',
    component: Layout,
    redirect: '/management/player',
    name: 'Management',
    meta: {
      title: '赛事管理中心',
      icon: 'heroicons:trophy-16-solid',
      alwaysShow: true
    },
    children: [
      {
        path: 'managementCenter/:id?',
        name: 'ManagementCenter',
        component: () => import('@/views/Management/ManagementCenter/ManagementCenter.vue'),
        meta: {
          title: '赛事概览',
          hidden: true
        }
      },
      {
        path: 'player',
        name: 'Player',
        component: () => import('@/views/Management/Player/Player.vue'),
        meta: {
          title: '选手汇总'
        }
      },
      {
        path: 'create',
        name: 'Create',
        component: () => import('@/views/Management/Create/Create.vue'),
        meta: {
          title: '赛事创建'
        }
      },
      {
        path: 'add',
        name: 'Add',
        component: () => import('@/views/Management/Create/components/Add.vue'),
        meta: {
          title: '赛事创建',
          hidden: true,
          noCache: true
        }
      },
      {
        path: 'arrangeDetails',
        name: 'ArrangeDetails',
        component: () => import('@/views/Management/ManagementCenter/ArrangeDetails.vue'),
        meta: {
          title: '赛程详情',
          hidden: true,
          noCache: true
        }
      },
      {
        path: 'edit/:id?',
        name: 'Edit',
        component: () => import('@/views/Management/Create/editComponets/Edit.vue'),
        meta: {
          title: '赛事编辑',
          hidden: true,
          noCache: true
        }
      },
      {
        path: 'points',
        name: 'Points',
        component: () => import('@/views/Management/Points/Points.vue'),
        meta: {
          title: '积分体系'
        }
      },
      {
        path: 'pointsSearch',
        name: 'PointsSearch',
        component: () => import('@/views/Management/PointsSearch/PointsSearch.vue'),
        meta: {
          title: '积分查询'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'Root']
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
